<template>
  <div>
    <Loading :loading="false" />
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("FIXTURE_TIP_DETAILS_PAGE") }}
              </h6>
            </div>
            <div class="card text-center mb-4">
              <div class="card-body">
                <h5 class="card-title">TORQUAY UNITED VS MAIDSTONE</h5>
                <p class="card-text">ENGLAND 6-NATIONAL LEAGUE SOUTH</p>
                <div class="row justify-content-md-center">
                  <div class="col col-lg-2"><h1>H</h1></div>
                  <div class="col-md-auto">
                    07.10.23 <br />
                    <h5>16:00</h5>
                  </div>
                  <div class="col col-lg-2"><h1>A</h1></div>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("TOP_PERFORMING_PREMIUM_TIPSTERS") }}
              </h6>
            </div>

            <div class="card">
              <div class="card-body">
                <div
                  class="d-flex align-items-center justify-content-between px-4 mb-3"
                >
                  <div>
                    <h5 class="card-title">ADAM SALAS</h5>
                    <p class="card-text">Premium Tipsters</p>
                  </div>

                  <a href="#" class="btn btn-primary btn-sm">{{
                    $t("VIEW_PROFILE_DETAILS")
                  }}</a>
                </div>
              </div>
              <div class="col-lg-12">
                <div
                  class="d-flex align-items-center justify-content-between px-4 mb-3"
                >
                  <div class="col-lg-4">
                    <div class="card text-center">
                      <div class="card-header p-0">FOLLOWERS</div>
                      <div class="card-body p-0">
                        <h2 class="card-title m-0">193</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card text-center">
                      <div class="card-header p-0">BETTING TIPS</div>
                      <div class="card-body p-0">
                        <h2 class="card-title m-0">4721</h2>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div
                      class="card text-center"
                      style="
                        background-color: rgba(0, 108, 9, 0.638);
                        border: 1px solid rgba(3, 87, 0, 0.633);
                        color: white;
                      "
                    >
                      <div
                        class="card-header p-0"
                        style="
                          background-color: rgba(0, 108, 9, 0.638);
                          border: 1px solid rgba(3, 87, 0, 0.633);
                        "
                      >
                        PROFITS
                      </div>
                      <div class="card-body p-0">
                        <h2 style="color: white" class="card-title m-0">
                          +35647.00
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-3">
              <div class="card-body">
                <RuningTips />
              </div>
            </div>
          </div>

          <Countrise />
        </div>
      </div>
    </div>
    <!-- Main News Slider End -->
  </div>
</template>

<script>
import Countrise from "../components/Countrise.vue";
import LeaderCard from "../components/LeaderCard.vue";
import Loading from "../components/Loading.vue";
import TipstersCard from "../components/TipstersCard.vue";

import History from "../components/History.vue";
import RuningTips from "../components/RuningTips.vue";

export default {
  computed: {
    currentRouteName() {
      return this.$routes.history.current.params.name;
    },
  },
  components: {
    Loading,
    TipstersCard,
    LeaderCard,
    Countrise,
    History,
    RuningTips,
  },
  mounted() {
    console.log(this.$routes.history.current.params.name);
  },
  data() {
    return {
      tipsters: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
      tipsters_week: [
        {
          rank: 1,
          name: "Netiphong Kanyala",
          profit: "+184.00",
        },
        {
          rank: 2,
          name: "RASMUS KOVALAINEN",
          profit: "+174.00",
        },
        {
          rank: 3,
          name: "CAUÃ£ ALMEIDA SILVA",
          profit: "+173.00",
        },
        {
          rank: 4,
          name: "BRANIMIR Ã…Â½IVKOVIÃ„Â€¡",
          profit: "+169.00",
        },
        {
          rank: 5,
          name: "CRISTIANO MONALDO",
          profit: "+164.00",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
.mini-nav {
  background-color: #1c50ed !important;
  height: 37px;
  width: 100%;
  left: 0;

  .link {
    height: 37px;
    width: 162px;
    left: 0;
    font-weight: bold;
    font-size: 14px;
    align-items: center !important;
    padding-top: 0.5rem !important;
    color: white;
    cursor: pointer;
  }
  .link:hover {
    background-color: #ffffff !important;
    color: #000000;
    cursor: pointer;
  }
  .active {
    background-color: #ffffff !important;
    color: #000000;
  }
  .active:hover {
    background-color: #ffffff !important;
    color: #000000;
  }
}
</style>
